import React from 'react';
import './App.css';

import { SetList, SetShow, SetCreate, SetEdit } from "./sets";
import { Admin, Resource } from 'react-admin';

import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  FirebaseRealTimeSaga
} from 'react-admin-firebase';

const config = {
  apiKey: "AIzaSyCSxgGhaZRi5eNP5nbDVk1KcL_HgTkdE9w",
  authDomain: "thirtywords-bb8d2.firebaseapp.com",
  databaseURL: "https://thirtywords-bb8d2.firebaseio.com",
  projectId: "thirtywords-bb8d2",
  storageBucket: "thirtywords-bb8d2.appspot.com",
  messagingSenderId: "857957392703",
  appId: "1:857957392703:web:99b720709db33712"
};

const options = {
  logging: true
}

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

class App extends React.Component {
  render() {
    return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="sets"
          list={SetList}
          show={SetShow}
          create={SetCreate}
          edit={SetEdit}
        />
      </Admin>
    );
  }
}

export default App;

