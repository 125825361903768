import * as React from "react";

import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    Filter,
    DisabledInput,
    SimpleShowLayout,
    SimpleForm,
    SimpleList,
    ChipField,
    SingleFieldList,
    ReferenceInput,
    BooleanInput, 
    BooleanField, 
    SimpleFormIterator,
    ArrayInput,
    TextField,
    TextInput,
    ShowButton,
    EditButton,
    DeleteButton,
    RichTextField,
    ArrayField,
    SelectInput
} from "react-admin";

const SetFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="title" alwaysOn />
    </Filter>
);

export const SetList = (props) => (
    <List {...props} filters={<SetFilter />}>
        <Datagrid>
            <TextField source="title" />
            <TextField source="slug" />
            <RichTextField source="description" />
            <RichTextField source="language" />
            <ShowButton label="" />
            <EditButton label="" />
            {/* <DeleteButton label="" redirect={false}/> */}
        </Datagrid>
    </List>
);

export const SetShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="slug" />
            <BooleanField source="free" />
            <RichTextField source="description" />
            <TextField source="language" />
            <ArrayField source="words">
                <SingleFieldList>
                    <ChipField source="word" />
                </SingleFieldList>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export const SetCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="title" />
            <TextInput source="slug" />
            <TextInput source="description" />
            <BooleanInput label="Free" source="free" />
            <SelectInput source="language" choices={[
                { id: 'de', name: 'de' },
                { id: 'en', name: 'en' },
                { id: 'es', name: 'es' },
            ]} />
            <ArrayInput source="words">
                <SimpleFormIterator>
                    <TextInput source="word" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export const SetEdit = (props) => {
    console.log(props);
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" />
                <TextInput source="slug" />
                <TextInput source="description" />
                <BooleanInput label="Free" source="free" />
                <SelectInput source="language" choices={[
                    { id: 'de', name: 'de' },
                    { id: 'en', name: 'en' },
                ]} />
                <ArrayInput source="words">
                    <SimpleFormIterator>
                        <TextInput source="word" />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    )
};